import React from 'react'
import { Link } from 'gatsby'

import '../../styles/nosotros.scss'

export default function Nosotros() {
	return (
		<React.Fragment>
			<div className="about" id="nosotros">
				<div className="about__title">
					<div className="about__texture" />
					<h1>SOBRE NOSOTROS</h1>
				</div>
				<Link to="/nosotros/filosofia" className="about__item about__item--1">
					<h2 className="about__subtitle">FILOSOFÍA</h2>
				</Link>
				<Link to="/nosotros/experiencia" className="about__item about__item--2">
					<h2 className="about__subtitle">EXPERIENCIA</h2>
				</Link>
				<Link to="/nosotros/quienes-somos" className="about__item about__item--3">
					<h2 className="about__subtitle">¿QUIÉNES SOMOS?</h2>
				</Link>
			</div>
		</React.Fragment>
	)
}
